:root {
  font-family: Basic-sans, sans-serif;
}
body {
  margin: 0;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  background-color: #f3f4f6;
  padding: 0.25em;
}
::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 100rem;
}
.hide {
  display: none;
}
.show {
  display: flex;
}
.caldata-header-wrapper {
  display: flex;
  background-color: white;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  color: white;
  padding: 0.5em 2em 1em 2em;
  overflow: hidden;
  box-shadow: 0px 0.5em 2em 0 #71717a;
  padding-bottom: 0;
}
.caldata-header-wrapper h1 {
  font-family: basic-sans, sans-serif !important;
}
.logo {
  padding: 0.5em 0em;
  width: 30em;
  max-width: 40%;
  height: auto;
  object-fit: contain;
  margin: 0;
}
.logo-wrapper {
  display: flex;
  gap: 0em 1em;
  margin-left: 1.5em;
  align-items: center;
  flex-wrap: wrap;
}
.logo-wrapper label {
  color: black;
  font-size: 3rem;
  font-weight: 100;
}
.navbar-wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0em 2em;
  margin-left: auto;
  color: black;
  padding: 1em;
  margin-right: 2em;
  padding-bottom: 0;
  font-size: 1.65em;
  flex-wrap: wrap;
}
.navbar-toggle {
  display: flex;
  font-size: 5em;
  display: none;
}
.navbar-item {
  cursor: pointer;
}
.navbar-item h1 {
  position: relative;
  font-weight: 100;
  font-size: 1.2em;
  padding: 0em 0.25em;
  padding-bottom: 10px;
}
.navbar-item h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 10px;
  border-radius: 1em;
  background-color: #b71233;
  transition: 1s;
}
.navbar-item:hover h1::after,
.navbar-item:focus h1::after {
  width: 100%;
  color: white;
}
@media screen and (max-width: 1000px) {
  .caldata-header-wrapper {
    justify-content: space-around;
  }
  .logo-wrapper {
    margin-left: 0;
  }
  .navbar-toggle {
    display: flex;
    align-self: center;
    margin-left: auto;
    margin-right: 1em;
  }
  .navbar-wrapper {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-end;
    text-align: right;
    justify-content: flex-end;
    float: right;
    position: absolute;
    margin: 0;
    background-color: #f3f4f6;
    margin-right: 0em;
    margin-right: 0.5em;
    border-radius: 0em 0em 2.5em 2.5em;
    border: 3px solid white;
    right: 0;
    gap: 1em;
    z-index: 99;
    transform: translateY(100%);
  }
  .navbar-item h1 {
    margin: 0;
  }
  .navbar-wrapper.hide {
    display: none;
  }
  .navbar-wrapper.hide .navbar-item {
    display: none;
  }
  .navbar-wrapper .show {
    display: flex;
  }
  .toggle-list {
    right: 0;
  }
}
.map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32em;
  min-width: 30%;
  max-height: 60%;
}
.map-wrapper {
  margin-top: 1em;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em 4em;
  justify-content: center;
}

.fast-facts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.9em;
  background-color: #f1f5f9;
  padding: 2em;
  border-radius: 2rem;
}
.fast-facts-wrapper h1 {
  padding: 0.3em;
  padding-bottom: 0;
  font-size: 2.3em;
  margin-right: auto;
  margin: 0;
  width: 100%;
  text-align: left;
}
.fact-wrapper {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-top: 0;
  padding: 0em 0.25em;
}
.two-facts {
  justify-content: space-around;
}
.fact-wrapper h2 {
  font-weight: 300;
  margin: 0;
  font-size: 1.6rem;
}
.county {
  fill: #d1d5db;
}
.county:hover {
  fill: #bae6fd;
}
.county.active {
  fill: #38bdf8;
}
.toggle-list {
  padding: 0.5em;
  display: flex;
  font-size: 3.5em;
  margin: 0.5em 0.5em;
  background-color: #e5e7eb;
  border: none;
  position: absolute;
  border-radius: 10000rem;
  cursor: pointer;
  transition: 0.3s;
}
.toggle-list:hover {
  background-color: #d1d5db;
  font-size: 4em;
}
.list-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  width: 95%;
  height: 80vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 1em;
  background-color: #e2e8f0;
  overflow-y: scroll;
  overflow-x: scroll;
}
.list-item {
  justify-content: space-around;
  white-space: nowrap;
  gap: 7em;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: max-content;
  min-width: 100%;
}
.list-item h1 {
  padding: 0em 1em;
}
.list-item h2 {
  padding: 0em 0.5em;
}
.dark {
  background-color: #cbd5e1;
}
.light {
  background-color: #e2e8f0;
}
.icon {
  font-size: 3em;
}
.quit-button {
  color: #ef4444;
  font-size: 4em;
  left: 1%;
  z-index: 1;
  position: absolute;
}
.c-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.c-wrapper h1 {
  font-size: 3em;
  font-weight: 800;
  margin-bottom: 0;
}
.c-wrapper p {
  margin: 0;
  font-size: 1.5em;
  width: 80%;
  max-width: 1000px;
  text-indent: 0 !important;
}
.return-button {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 1.5em;
  border: none;
  background: hsla(14, 90%, 73%, 1);
  font-family: basic-sans, sans-serif;
  color: white;
  font-weight: 600;
  border-radius: 10em 10em 0em 0em;
  padding: 0.25em 1em;
  background: linear-gradient(
    90deg,
    hsla(14, 90%, 73%, 1) 0%,
    hsla(331, 54%, 44%, 1) 100%
  );
  transition: 0.4s;
}
.return-button:hover {
  color: #e5e7eb;
  padding: 0.2em 0.8em;
}
a {
  text-decoration: none;
  color: inherit;
}
